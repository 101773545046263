import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import PageComponent from './pages';
import CampaignDonation from './pages/campaign-donation/campaign-donation';
import RegionDonation from './pages/region-donation/region-donation';
import RegionTotal from './pages/region-total/region-total';
import CampaignTotal from './pages/campaign-total/campaign-total';

const Dashboard = () => {
  return (
    <Outlet />
  )
}

function App() {

  return (
    <Router>
      <div className="App">
        <div className="app-container">
        <Routes>
        <Route 
            element={<Dashboard/>}
            path="/"
            key="DashboardPage"
          >
            <Route path="/v1/regions/last-donation/:id" element={<PageComponent component={RegionDonation} title="Last Donation" />} />
            <Route path="/v1/campaigns/last-donation/:id" element={<PageComponent component={CampaignDonation} title="Last Donation" />} />
            <Route path="/v1/regions/total/:id" element={<PageComponent component={RegionTotal} title="Total" />} />
            <Route path="/v1/campaigns/total/:id" element={<PageComponent component={CampaignTotal} title="Total" />} />
          </Route>
        </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
