import * as React from "react"
import { useLayoutEffect } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { TITLE_APP, getLanguageDefault } from "../utils";
import { withTranslation } from "react-i18next";

const PageComponent = (props: any) => {

 useDocumentTitle(`${TITLE_APP}${props.title}`);

 const { i18n } = props;
    var timeoutLanguage:any = null;
    useLayoutEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timeoutLanguage = setTimeout(() => {
            i18n.changeLanguage(getLanguageDefault().code.toLowerCase());
        }, 10);

        return () => {
            clearTimeout(timeoutLanguage);
          };
    }, []);


  return (
      <props.component />
  );
}

export default withTranslation('language')(PageComponent);
