import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./campaign-donation.scss";
import { WidgetAPI } from "../../services";
import ParametersWidget from '../../common/Parameters';
import i18n from 'i18next';
import { convertNumberToLocal, LanguageCode, loadGoogleFont, WIDGET_TYPE } from "../../utils";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CampaignDonation = (props: any) => {
  const [widgetData, setWidgetData] = useState<any>();
  const parameters:any = new ParametersWidget(window.location.search, WIDGET_TYPE.DONATION);
  const { id } = useParams();
  const intervalRef = useRef<any>(null);
  const { t } = useTranslation('language', { keyPrefix: 'donation_widget' });
  var timeoutLanguage: any = null;
  
  const getDonation = async () => {
    try {
      const { data: widget } = await WidgetAPI.getCampaignDonation(id);
      if(widget) {
        setWidgetData(widget?.result)
      }
    } catch (error: any) {
      if (error?.response?.status === 404 && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  };

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeoutLanguage = setTimeout(() => {
      i18n.changeLanguage(parameters?.language?.toLowerCase() || LanguageCode.DE);
    }, 50); 
    return () => {
      clearTimeout(timeoutLanguage);
    };
  }, [parameters?.language]);

  useEffect(() => {
    getDonation();
    loadGoogleFont(parameters?.fontFamily || 'Arial');
    intervalRef.current = setInterval(() => {
      getDonation();
    }, 3000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <div className="donation-widget">
        <div 
          className="last-donation w-100 d-flex pb-24"
          style={{justifyContent: parameters?.align, backgroundColor: `#${parameters?.backgroundColor}`, textAlign: parameters?.align}}
          >
            <div className="widget">
              {
                parameters?.headline &&
                <h3 
                  className="m-0" 
                  style={{
                    fontWeight: parameters?.headlineWeight, 
                    color: `#${parameters?.textColor}`,
                    fontSize: `${parameters?.fontSize}px`,
                    fontFamily: `'${parameters?.fontFamily}', sans-serif`
                  }} 
                  dangerouslySetInnerHTML={{__html: parameters?.headline}}
                  ></h3>
              }
              <p 
                className="m-0 pt-10" 
                style={{
                  fontWeight: parameters?.donorNameWeight, 
                  color: `#${parameters?.textColor}`,
                  fontSize: `${parameters?.fontSize}px`,
                  fontFamily: `'${parameters?.fontFamily}', sans-serif`
                }}
                > {widgetData ? widgetData?.last_donation?.donor_name?.trim() || t('txt_anonymous') : 'N/A'} </p>
              <p 
                className="m-0 pt-10" 
                style={{
                  fontWeight: parameters?.valueWeight, 
                  color: `#${parameters?.textColor}`,
                  fontSize: `${parameters?.fontSize}px`,
                  fontFamily: `'${parameters?.fontFamily}', sans-serif`
                }}
                > {convertNumberToLocal(parameters, widgetData?.last_donation)}</p>
            </div>
        </div>
    </div>
  );
};

export default CampaignDonation;
