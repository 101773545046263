import { AxiosClient } from "./httpClient";

export default class WidgetAPI {
  static getRegionDonation(code: any) {
    return AxiosClient.get(`/statistics/v1/regions/${code}`);
  }

  static getCampaignDonation(code: any) {
    return AxiosClient.get(`/statistics/v1/campaigns/${code}`);
  }
}