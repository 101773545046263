import Axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { env } from '../environment';
// import moment from 'moment';
import { setupCache } from 'axios-cache-adapter'
import localforage from 'localforage';

const AXIOS_URL = env.AXIOS_URL;

const paramsSerializer = (params: any) => {
  const searchParams = new URLSearchParams()
  for (const key of Object.keys(params)) {
    const param = params[key]
    if (Array.isArray(param)) {
      for (const p of param) {
        searchParams.append(key, p)
      }
    } else {
      searchParams.append(key, param)
    }
  }
  return searchParams.toString()
}


const forageStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB, 
    localforage.LOCALSTORAGE,
  ],
  name: 'wild-cache',
});


const customCaches = setupCache({
  maxAge: 0, // 0 mean no cache
  exclude: { 
    query: false,
  },
  store: forageStore,
  invalidate: async (config: any, request: any) => {
    if (request.clearCacheEntry) {
      // @ts-ignore
      await config.store.removeItem(config.uuid);
    }
  },
});

export const cacheAge: any = {
  maxAge: 2 * 60 * 1000 // cache data in 2 mins
};

export const AxiosServerInstance = Axios.create({
  baseURL: `${AXIOS_URL}/`,
  timeout: 1800000,
  headers: {
    'Content-Type': 'application/json',
    // 'wd-signature': env.SIGNATURE
  },
  adapter: customCaches.adapter,
  paramsSerializer
})

export const AxiosDrupalInstance = Axios.create({
  baseURL: `${AXIOS_URL}/`,
  timeout: 1800000,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer
})

export class HttpClient {
  constructor(private axiosInstance: AxiosInstance) { }

  request(config: AxiosRequestConfig) {

    config.params = {
      ...config.params,
    }

    return this.axiosInstance.request(config)
  }

  get(url: string, config?: any, headers?: any) {
    const maxAge = config?.maxAge || 5 * 1000;
    return this.axiosInstance.get(url, { 
      params: config, 
      headers, 
      cache: { 
        maxAge // Override `maxAge` for this request only
      } 
    });
  }

  post(url: string, data: {}, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }
    }

    return this.axiosInstance.post(url, data, config)
  }

  put(url: string, data = {}, headers?: any, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }
    }

    return this.axiosInstance.put(url, data, { 
      params: config, 
      headers
    })
  }

  patch(url: string, data = {}, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }
    }

    return this.axiosInstance.patch(url, data, config)
  }

  delete(url: string, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }

      config.data = {
        ...config.data,
      }
    }

    return this.axiosInstance.delete(url, config)
  }

  all(requests: any[]) {
    return Axios.all(requests)
  }
}

export const AxiosClient = new HttpClient(AxiosServerInstance);
export const AxiosDrupalClient = new HttpClient(AxiosDrupalInstance);

// export const refreshJWT = (token: string) => {
//   return AxiosDrupalClient.get(`/jwt/token`, undefined, { 'Authorization': 'Bearer ' + token });
// }

// AxiosServerInstance.interceptors.request.use(
//   async config => {
//     const accessToken = localStorage.getItem('access_token');
//     const expireTime = localStorage.getItem('expire_time');
//     const isActiveToken = moment().diff(expireTime);
    
//     if(isActiveToken > 0) {
//       return Promise.reject({ response: { status: 401 } });
//     }
    
//     const newHeaders: typeof config.headers = {
//       'Content-Type': 'application/json',
//       ...config.headers,
//     };

//     if(accessToken) {
//       newHeaders['Authorization'] = `Bearer ${accessToken}`
//     }

//     config.headers = newHeaders;
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// const refreshJWTToken = async () => {
//   const accessToken = localStorage.getItem('access_token');
//   const expireTime = localStorage.getItem('expire_time');
//   localStorage.setItem('expire_time', moment().add('hours', 1).toISOString());

//   if (moment(expireTime).diff(moment(), 'minutes') < 55) {
//     const token = await refreshJWT(`${accessToken}`);
//     localStorage.setItem('access_token', token?.data?.token);
//     localStorage.setItem('expire_time', moment().add('hours', 1).toISOString());
//   }
// }

// AxiosServerInstance.interceptors.response.use(
//   async response => {
//     if(response && (response.status === 200 || response.status === 201)) {
//       refreshJWTToken();
//     }
//     return response;
//   },
//   async error => {
//     const { response } = error;
//     if (response && response.status === 401) {
//       localStorage.setItem('access_token', '');
//       localStorage.setItem('expire_time', '');
//       window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   }
// );