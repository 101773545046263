import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./region-total.scss";
import { WidgetAPI } from "../../services";
import ParametersWidget from '../../common/Parameters';
import i18n from 'i18next';
import { convertNumberToLocalTotal, LanguageCode, loadGoogleFont, WIDGET_TYPE } from "../../utils";
import { useParams } from "react-router-dom";

const RegionTotal = (props: any) => {
  const [widgetData, setWidgetData] = useState<any>();
  const parameters:any = new ParametersWidget(window.location.search, WIDGET_TYPE.TOTAL);
  const { id } = useParams();
  const intervalRef = useRef<any>(null);
  
  const getTotal = async () => {
    try {
      const { data: widget } = await WidgetAPI.getRegionDonation(id);
      if(widget) {
        setWidgetData(widget?.result)
      }
    } catch (error: any) {
      if (error?.response?.status === 404 && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  };

  useLayoutEffect(() => {
    i18n.changeLanguage(parameters?.language?.toLowerCase() || LanguageCode.DE);
  }, [parameters?.language]);

  useEffect(() => {
    getTotal();
    loadGoogleFont(parameters?.fontFamily || 'Arial');
    intervalRef.current = setInterval(() => {
      getTotal();
    }, 3000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <div className="total-widget">
        <div 
          className="last-total w-100 d-flex pb-24"
          style={{justifyContent: parameters?.align, backgroundColor: `#${parameters?.backgroundColor}`, textAlign: parameters?.align}}
          >
            <div className="widget">
            {
                parameters?.headline &&
                <h3 
                  className="m-0" 
                  style={{
                    fontWeight: parameters?.headlineWeight, 
                    color: `#${parameters?.textColor}`,
                    fontSize: `${parameters?.fontSize}px`,
                    fontFamily: `'${parameters?.fontFamily}', sans-serif`
                  }} 
                  dangerouslySetInnerHTML={{__html: parameters?.headline}}
                  ></h3>
              }
              <p 
                className="m-0 pt-10" 
                style={{
                  fontWeight: parameters?.valueWeight, 
                  color: `#${parameters?.textColor}`,
                  fontSize: `${parameters?.fontSize}px`,
                  fontFamily: `'${parameters?.fontFamily}', sans-serif`
                }}
                > {convertNumberToLocalTotal(parameters, widgetData)}</p>
            </div>
        </div>
    </div>
  );
};

export default RegionTotal;
